import {
  FlowComponent,
  Show,
  For,
  JSXElement,
  type ComponentProps,
} from "solid-js";
import { useNavigate } from "@solidjs/router";
import { Icon } from "solid-heroicons";
import { DropdownMenu } from "@kobalte/core";
import { BaseLoader } from "./Loader";
import "./menu.css";

type DropdownProps = ComponentProps<typeof DropdownMenu.Root>;

type MenuItem = {
  label: string | JSXElement;
  disabled?: boolean;
  new?: boolean;
  icon?: { path: JSXElement } | JSXElement;
  newTab?: boolean;
} & ({ href: string } | { onSelect: () => void });

export type MenuGroup = {
  label?: string | JSXElement;
  list: MenuItem[];
};

type MenuProps = {
  list: MenuGroup[];
  onOpenChange?: (open: boolean) => void;
  loading?: boolean;
  footer?: string | JSXElement;
  gutter?: DropdownProps["gutter"];
  placement?: DropdownProps["placement"];
  header?: string | JSXElement
};

export const Menu: FlowComponent<MenuProps> = (props) => {
  const navigate = useNavigate();

  const itemClass =
    "text-sm cursor-pointer rounded-[4px] flex items-center px-6 py-2 relative select-none outline-none h-8 data-[highlighted]:bg-roma-blue data-[highlighted]:text-white data-[disabled]:text-roma-medium-grey data-[disabled]:pointer-events-none group whitespace-nowrap";

  const contentClass =
    "min-w-[240px] p-2 rounded-md border border-roma-grey shadow-md outline-none bg-white z-30  max-h-[85vh] overflow-y-auto";

  const groupLabel = "px-6 text-roma-medium-grey text-sm";

  const newPill =
    "flex bg-roma-blue text-white group-hover:bg-white group-hover:text-roma-blue justify-center items-center text-xs rounded-full px-2 ml-3";

  return (
    <DropdownMenu.Root
      gutter={props.gutter ?? 16}
      modal={false}
      hideWhenDetached
      {...props}
    >
      <DropdownMenu.Trigger>{props.children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content class={`dropdown-menu__content ${contentClass}`}>
          <Show when={props.loading}>
            <div class="flex items-center justify-center py-6 px-4">
              <BaseLoader width={8} height={8} />
            </div>
          </Show>
          <Show when={props.header}>
            {props.header}
            <DropdownMenu.Separator class="dropdown-menu__separator"/>
          </Show>
          <For each={props.list}>
            {(group, index) => (
              <>
                <Show when={group.label && group.list.length > 0}>
                  <DropdownMenu.Group>
                    <DropdownMenu.GroupLabel class={groupLabel}>
                      {group.label}
                    </DropdownMenu.GroupLabel>
                  </DropdownMenu.Group>
                </Show>

                <For each={group.list}>
                  {(item) => (
                    <DropdownMenu.Item
                      class={itemClass}
                      onSelect={() => {
                        if ("onSelect" in item && item.onSelect) {
                          item.onSelect();
                        } else if (
                          "href" in item &&
                          item.href &&
                          !item.newTab
                        ) {
                          navigate(item.href);
                        } else if ("href" in item && item.href && item.newTab) {
                          window.open(
                            item.href,
                            "_blank",
                            "rel=noopener noreferrer"
                          );
                        }
                      }}
                    >
                      <Show when={item.icon}>
                        <Icon
                          path={item.icon as IconPath}
                          class="w-5 mr-2 shrink-0"
                        />
                      </Show>
                      {item.label}
                      <Show when={item.new}>
                        <div class={newPill}>New</div>
                      </Show>
                    </DropdownMenu.Item>
                  )}
                </For>
                <Show when={index() !== props.list.length - 1 && group.list.length > 0}>
                  <DropdownMenu.Separator class=" dropdown-menu__separator" />
                </Show>
              </>
            )}
          </For>

          <Show when={props.footer}>
            <DropdownMenu.Separator class="dropdown-menu__separator" />
            <div class="px-6 py-2">{props.footer}</div>
          </Show>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
